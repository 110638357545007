import React, {useState} from 'react';
import {Accordion, AccordionDetails, AccordionSummary, Box, Button} from "@mui/material";
import {ModulesData} from "../../data/data";

interface ModuleProps {
    title: string;
    description: string[]
}

const PlusIcon = () => {
    return (
        <svg className={'module-icon'} width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_bd_84_898)">
                <circle cx="35" cy="31" r="31" fill="url(#paint0_linear_84_898)" fillOpacity="0.1" shapeRendering="crispEdges"/>
                <circle cx="35" cy="31" r="30.5" stroke="white" strokeOpacity="0.2" shapeRendering="crispEdges"/>
            </g>
            <ellipse cx="34.9998" cy="30.9998" rx="25.7234" ry="26.1631" fill="white"/>
            <path d="M36.4927 42H33.5073V33.0428H25V29.9572H33.5073V21H36.4927V29.9572H45V33.0428H36.4927V42Z" fill="black"/>
            <defs>
                <filter id="filter0_bd_84_898" x="-32" y="-36" width="134" height="134" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feGaussianBlur in="BackgroundImageFix" stdDeviation="18"/>
                    <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_84_898"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="4"/>
                    <feGaussianBlur stdDeviation="2"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0.866667 0 0 0 0 0.866667 0 0 0 0 0.866667 0 0 0 0.2 0"/>
                    <feBlend mode="normal" in2="effect1_backgroundBlur_84_898" result="effect2_dropShadow_84_898"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_84_898" result="shape"/>
                </filter>
                <linearGradient id="paint0_linear_84_898" x1="35" y1="0" x2="35" y2="62" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#E8E8E8"/>
                    <stop offset="1" stopColor="#E8E8E8" stopOpacity="0"/>
                </linearGradient>
            </defs>
        </svg>
    )
}

const ProgrammModule = ({title, description}: ModuleProps) => {

    const [isExpanded, setIsExpanded] = useState<boolean>(false);

    const handleExpand = (event:any, isExpanded:boolean) => {
        setIsExpanded(isExpanded);
    }

    return (
        <Accordion
            className={'module-accordion'}
            onChange={handleExpand}
        >
            <AccordionSummary
                expandIcon={!isExpanded ? <PlusIcon /> : null}
                className={'module-accordion-summary'}
            >
                {title}
            </AccordionSummary>
            <AccordionDetails
                className={'module-accordion-details'}
            >
                <ul>
                    {description.map((listItem) => {
                        return (
                            <li key={listItem}>
                                {listItem}
                            </li>
                        )
                    })}
                </ul>
            </AccordionDetails>
        </Accordion>
    )
}

const Programm = () => {

    const [show, setShow] = useState<number>(3);

    return (
        <Box id={'programm'} className={'programm'}>
            <h1 className={'h1'}>
                <span className={'red'}>Программа </span>
                <span className={'gray'}>курса</span>
            </h1>
            {ModulesData.map((module, index) => {
                if (index <= show) {
                    return (
                        <ProgrammModule key={module.title} title={module.title} description={module.description} />
                    )
                } else {
                    return null;
                }
            })}
            {show === 3 && (<Button
                variant={'outlined'}
                onClick={() => {
                    setShow(13)
                }}
                className={'programm-btn'}
            >
                Показать все модули
            </Button>)}
            {show === 13 && (<Button
                variant={'outlined'}
                onClick={() => {
                    setShow(3)
                }}
                className={'programm-btn'}
            >
                Скрыть модули
            </Button>)}
        </Box>
    );
};

export default Programm;