export const ModulesData = [
    {
        title: 'Модуль #1',
        description: [
            'Введение',
            'Общие обязанности водителей',
            'Общие обязанности пешеходов и пассажиров',
        ]
    },
    {
        title: 'Модуль #2',
        description: [
            'Дорожные знаки',
            'Знаки приоритета',
            'Запрещенные знаки'
        ]
    },
    {
        title: 'Модуль #3',
        description: [
            'Предписывающие знаки',
            'Информационно указательные (часть 1)',
            'Информационно указательные (часть 2)',
            'Знаки сервиса'
        ]
    },
    {
        title: 'Модуль #4',
        description: [
            'Дорожная разметка',
            'Сигналы оповещения'
        ]
    },
    {
        title: 'Модуль #5',
        description: [
            'Сигналы светофора и регулировщика (часть 1)',
            'Сигналы светофора и регулировщика (часть 2)',
            'Сигналы светофора и регулировщика (часть 3',
            'Сигналы оповещения'
        ]
    },
    {
        title: 'Модуль #6',
        description: [
            'Начало движения, маневрирование (часть 1)',
            'Начало движения, маневрирование (часть 2)',
            'Начало движения, маневрирование (часть 3)'
        ]
    },
    {
        title: 'Модуль #7',
        description: [
            'Расположение транспортных средств на проезжей части (часть 1)',
            'Расположение транспортных средств на проезжей части (часть 2)',
        ]
    },
    {
        title: 'Модуль #8',
        description: [
            'Скорость движения',
            'Обгон, опережение, встречный разъезд',
            'Остановка и стоянка'
        ]
    },
    {
        title: 'Модуль #9',
        description: [
            'Проезд перекрестков (часть 1)',
            'Проезд перекрестков (часть 2)',
            'Проезд перекрестков (часть 3)',
        ]
    },
    {
        title: 'Модуль #10',
        description: [
            'Пешеходные переходы и места остановки маршрутных ТС',
            'Движение через ЖД переезд',
            'Движение по автомагистрали',
        ]
    },
    {
        title: 'Модуль #11',
        description: [
            'Приоритет маршрутных ТС',
            'Пользование внешними СВ приборами',
            'Буксировка мех ТС средств',
        ]
    },
    {
        title: 'Модуль #12',
        description: [
            'Учебная езда',
            'Перевозка людей',
            'Перевозка грузов',
        ]
    },
    {
        title: 'Модуль #13',
        description: [
            'Основа безопасного движения (часть 1)',
            'Основа безопасного движения (часть 2)',
            'Основа безопасного движения (часть 3)',
        ]
    },
    {
        title: 'Модуль #14',
        description: [
            'Дополнительные требования к движению мотоциклов',
            'Оказание первой помощи ДТП',
            'Основные положения по допуску ТС к эксплуатации',
        ]
    },
]

export const QuestionsData = [
    {
        title: 'Я никогда не изучал(а) ПДД, смогу обучиться онлайн?',
        description: 'Конечно! На нашей платформе Вы сможете начать обучение с нуля, даже если у вас нет базовых знаний.'
    },
    {
        title: 'В какое время проводятся занятия, смогу ли я совмещать их с учебой или работой?',
        description: 'Обучаться онлайн можно в любое удобное для Вас время, доступ к материалам обучения будет предоставлен Вам 24/7, поэтому совмещать с другими занятиями безусловно можно. Также имеется очное обучение в классе, наши менеджеры подберут Вам удобное расписание.'
    },
    {
        title: 'Получу ли я права по окончанию курса?',
        description: 'По окончанию курса Вы получите свидетельство об окончании курса, с помощью которого у Вас будет возможность сдавать экзамены в неограниченном количестве попыток в течение 2-х лет. К тому же, у нас есть все необходимые материалы для подготовки к экзаменам и гарантируем получение прав с первой же попытки!'
    },
    {
        title: 'Есть ли рассрочка?',
        description: 'Курс можно оформить в рассрочку, для этого необходимо обратиться к нашим менеджерам.'
    },
    {
        title: 'Есть ли занятия с автоинструктором?',
        description: 'У нас есть инструкторы по вождению в каждом городе Казахстана.'
    },
    {
        title: 'Срок обучения ',
        description: 'Есть 2 варианта обучения: стандартное (75 дней) и ускоренное (14 дней), подробнее с тарифами - можете ознакомиться на сайте.'
    },
    {
        title: 'Предоставляете ли вы доступ к экзаменационным тестам?',
        description: 'Да, записавшись на курс вы получите доступ к полной базе экзаменационных вопросов, которая 100% идентична с тестами СпецЦОН, что в свою очередь дает гарантию сдачи экзамена с 1-го раза!'
    }
]

export const FeedbacksData = [
    {
        imgPath: '/images/feedbacks/kursant1.jpg',
        feedback: 'Отличные курсы! Выбрала эти курсы по рекомендации друзей и знакомых, которые недавно получили права.'
    },
    {
        imgPath: '/images/feedbacks/kursant2.jpg',
        feedback: 'Самая лучшая Автошкола! Уроки проходят на позитиве, теорию разбирали каждый раздел досконально и при этом все легко запоминалось'
    },
    {
        imgPath: '/images/feedbacks/kursant3.jpg',
        feedback: 'Хорошие курсы, помогли самостоятельно подготовиться к сдаче прав за 2 недели, рекомендую! Разбираемые тесты аналогичны СпецЦОНовским, проверено на Астанинском'
    },
    {
        imgPath: '/images/feedbacks/kursant4.jpg',
        feedback: 'Большое спасибо Автоонлайн кз за подготовку к водительским экзаменам. В частности благодарю Куаныша. Ни капли не жалею о потраченных деньгах, и желаю автошколе дальнейшего процветания.'
    }
]