import React from 'react';
import { Box, Button, Link } from '@mui/material';

const Banner = () => {
    return (
        <Box className={'banner-ctn section'}>
            <Box className={'banner-mobile'}>
                <h1 className={'h1'}>
                    <span className={'red'}>Получи права без автошколы за 2 недели </span>
                    <span className={'gray'}>Авторский курс по самоподготовке</span>
                </h1>
            </Box>
            <img src={'/images/banner-mobile-bg.png'} alt={'Баннер'} className={'banner-background-mobile'}/>
            <img src={'/images/Banner3.png'} alt={'Баннер'} className={'banner-background'}/>
            <Link className={'banner-btn-link'} href={'#tariffs'}>
                <Button
                    className={'banner-btn'}
                    variant={'contained'}
                >
                    Хочу на курс
                    <svg style={{marginLeft: '5px'}} width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14.0833 7.875C13.7485 7.875 13.4806 7.98047 13.2462 8.15625C13.0788 7.34766 12.4427 6.75 11.6726 6.75C11.0699 6.75 10.5677 7.10156 10.2998 7.59375C10.1324 6.78516 9.49623 6.1875 8.72614 6.1875C8.29088 6.1875 7.92257 6.36328 7.65472 6.64453V1.40625C7.65472 0.632812 7.05204 0 6.31543 0C5.54534 0 4.97614 0.632812 4.97614 1.40625V12.7617L3.10114 10.125C2.83329 9.77344 2.4315 9.5625 1.99623 9.5625C1.29311 9.5625 0.69043 10.1953 0.69043 11.0039C0.69043 11.2852 0.757394 11.5664 0.958287 11.8125L4.00516 16.1016C4.84222 17.2969 6.21498 18 7.65472 18H10.869C13.5141 18 15.6904 15.75 15.6904 12.9375V9.5625C15.6904 8.64844 14.9538 7.875 14.0833 7.875ZM8.72614 14.0625C8.72614 14.3789 8.45829 14.625 8.19043 14.625C7.88909 14.625 7.65472 14.3789 7.65472 14.0625V10.6875C7.65472 10.4062 7.88909 10.125 8.19043 10.125C8.45829 10.125 8.72614 10.4062 8.72614 10.6875V14.0625ZM10.869 14.0625C10.869 14.3789 10.6011 14.625 10.3333 14.625C10.0319 14.625 9.79757 14.3789 9.79757 14.0625V10.6875C9.79757 10.4062 10.0319 10.125 10.3333 10.125C10.6011 10.125 10.869 10.4062 10.869 10.6875V14.0625ZM13.0119 14.0625C13.0119 14.3789 12.744 14.625 12.4761 14.625C12.1748 14.625 11.9404 14.3789 11.9404 14.0625V10.6875C11.9404 10.4062 12.1748 10.125 12.4761 10.125C12.744 10.125 13.0119 10.4062 13.0119 10.6875V14.0625Z" fill="white"/>
                    </svg>

                </Button>
            </Link>
        </Box>
    );
};

export default Banner;