import React from 'react';
import {Box, Button} from "@mui/material";

const Tariffs = ({onChoose, onClose} : any) => {
    return (
        <Box id={'tariffs'} className={'tariffs section'}>
            <h1 className={'h1'}>
                <span className={'red'}>Тарифы </span>
                <span className={'gray'}>курса</span>
            </h1>
            <Box className={'tariffs-card-ctn'}>
                <Box className={'tariffs-card'}>
                    <h3>Курс самоподготовки</h3>
                    <p>(14 дней)</p>
                    <ul>
                        <li>
                            Ежедневные разборы экзаменационных тестов с преподавателем (На весь срок обучения).
                        </li>
                        <li>
                            Тесты экзамена ЦОН-а (ДОСТУП НА 2 МЕС).
                        </li>
                        <li>
                            Видео курс по практическому экзамену (АВТОДРОМ) в ЦОН-е <br />
                            <b>(ДОСТУП НА 2 МЕС).В ПОДАРОК</b>
                        </li>
                    </ul>

                    <h1 className={'h1 red'}>15 000 тенге</h1>

                    <Button
                        variant={'contained'}
                        onClick={() => {onChoose('15000')}}
                    >
                        Выбрать тариф
                        <svg style={{marginLeft: '5px'}} width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.37909 4.57301C8.1908 4.57301 8.04018 4.63232 7.90838 4.73117C7.81423 4.27646 7.45649 3.94037 7.02343 3.94037C6.68452 3.94037 6.40209 4.13807 6.25146 4.41485C6.15732 3.96014 5.79957 3.62405 5.36652 3.62405C5.12175 3.62405 4.91463 3.7229 4.764 3.88106V0.935332C4.764 0.500392 4.42509 0.144531 4.01086 0.144531C3.5778 0.144531 3.25772 0.500392 3.25772 0.935332V7.32105L2.20331 5.8383C2.05269 5.6406 1.82674 5.52198 1.58197 5.52198C1.18657 5.52198 0.847656 5.87784 0.847656 6.33255C0.847656 6.49071 0.885313 6.64887 0.998285 6.78726L2.71169 9.1992C3.1824 9.87138 3.95437 10.2668 4.764 10.2668H6.57155C8.059 10.2668 9.28286 9.0015 9.28286 7.4199V5.52198C9.28286 5.00795 8.86863 4.57301 8.37909 4.57301ZM5.36652 8.05254C5.36652 8.23047 5.21589 8.36886 5.06526 8.36886C4.8958 8.36886 4.764 8.23047 4.764 8.05254V6.15462C4.764 5.99646 4.8958 5.8383 5.06526 5.8383C5.21589 5.8383 5.36652 5.99646 5.36652 6.15462V8.05254ZM6.57155 8.05254C6.57155 8.23047 6.42092 8.36886 6.27029 8.36886C6.10083 8.36886 5.96903 8.23047 5.96903 8.05254V6.15462C5.96903 5.99646 6.10083 5.8383 6.27029 5.8383C6.42092 5.8383 6.57155 5.99646 6.57155 6.15462V8.05254ZM7.77658 8.05254C7.77658 8.23047 7.62595 8.36886 7.47532 8.36886C7.30586 8.36886 7.17406 8.23047 7.17406 8.05254V6.15462C7.17406 5.99646 7.30586 5.8383 7.47532 5.8383C7.62595 5.8383 7.77658 5.99646 7.77658 6.15462V8.05254Z" fill="#FFFFFF"/>
                        </svg>
                    </Button>
                </Box>

                <Box className={'tariffs-card red-card'}>
                    <h3>Курс самоподготовки</h3>
                    <p>С выдачей сертификата автошколы 2.5 мес</p>
                    <ul>
                        <li>
                            Ежедневные разборы экзаменационных тестов с преподавателем (На весь срок обучения).
                        </li>
                        <li>
                            Тесты экзамена ЦОН-а (ДОСТУП НА 2 МЕС).
                        </li>
                        <li>
                            Видео курс по практическому экзамену (АВТОДРОМ) в ЦОН-е <br />
                            <b>(ДОСТУП НА 2 МЕС).В ПОДАРОК</b>
                        </li>
                    </ul>

                    <h1 className={'h1'}>25 000 тенге</h1>

                    <Button
                        variant={'outlined'}
                        onClick={() => {onChoose('25000')}}
                    >
                        Выбрать тариф
                        <svg style={{marginLeft: '5px'}} width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.37909 4.57301C8.1908 4.57301 8.04018 4.63232 7.90838 4.73117C7.81423 4.27646 7.45649 3.94037 7.02343 3.94037C6.68452 3.94037 6.40209 4.13807 6.25146 4.41485C6.15732 3.96014 5.79957 3.62405 5.36652 3.62405C5.12175 3.62405 4.91463 3.7229 4.764 3.88106V0.935332C4.764 0.500392 4.42509 0.144531 4.01086 0.144531C3.5778 0.144531 3.25772 0.500392 3.25772 0.935332V7.32105L2.20331 5.8383C2.05269 5.6406 1.82674 5.52198 1.58197 5.52198C1.18657 5.52198 0.847656 5.87784 0.847656 6.33255C0.847656 6.49071 0.885313 6.64887 0.998285 6.78726L2.71169 9.1992C3.1824 9.87138 3.95437 10.2668 4.764 10.2668H6.57155C8.059 10.2668 9.28286 9.0015 9.28286 7.4199V5.52198C9.28286 5.00795 8.86863 4.57301 8.37909 4.57301ZM5.36652 8.05254C5.36652 8.23047 5.21589 8.36886 5.06526 8.36886C4.8958 8.36886 4.764 8.23047 4.764 8.05254V6.15462C4.764 5.99646 4.8958 5.8383 5.06526 5.8383C5.21589 5.8383 5.36652 5.99646 5.36652 6.15462V8.05254ZM6.57155 8.05254C6.57155 8.23047 6.42092 8.36886 6.27029 8.36886C6.10083 8.36886 5.96903 8.23047 5.96903 8.05254V6.15462C5.96903 5.99646 6.10083 5.8383 6.27029 5.8383C6.42092 5.8383 6.57155 5.99646 6.57155 6.15462V8.05254ZM7.77658 8.05254C7.77658 8.23047 7.62595 8.36886 7.47532 8.36886C7.30586 8.36886 7.17406 8.23047 7.17406 8.05254V6.15462C7.17406 5.99646 7.30586 5.8383 7.47532 5.8383C7.62595 5.8383 7.77658 5.99646 7.77658 6.15462V8.05254Z" fill="#4E4E4E"/>
                        </svg>
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

export default Tariffs;