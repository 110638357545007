import axios from "axios";

const config = {
    headers: {
        "Content-Type": "application/json",
    }
}

const BackendURL = 'https://projecter.kz';

export const postAppeal = (data:any) => {
    return axios.post(`${BackendURL}/email`, data, config);
}
