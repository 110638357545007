import React from 'react';
import {Box} from "@mui/material";

const Kurators = () => {
    return (
        <Box id={'kurators'} className={'kurators'}>
            <Box className={'kurators-header'}>
                <img src={'/images/kurators/arrow.png'} alt={'Стрелка'} className={'arrow'} />
                <img src={'/images/kurators/left-block.png'} alt={'Левый блок'} className={'left-block'}/>
                <h1 className={'h1'}>
                    <span className={'red'}>Кураторы </span>
                    <span className={'gray'}>курса</span>
                </h1>
                <img src={'/images/kurators/right-block.png'} alt={'Правый блок'} className={'right-block'}/>
            </Box>
            <Box className={'kurators-ctn'}>
                <Box className={'kurators-card card-kuanish'}>
                   <img src={'/images/kuanish.jpeg'} alt={'Куратор'} className={'kurators-card-img'}/>
                   <h3>Куаныш</h3>

                   <p>Эксперт, привлеченный с портала pddtest.kz. Опытный преподаватель. Разработчик официальных тестов в СпецЦОНах РК.</p>
                </Box>
                <Box className={'kurators-card'}>
                    <img src={'/images/ayat.jpeg'}  alt={'Куратор'} className={'kurators-card-img'}/>
                    <h3>Аят</h3>

                    <p>Куратор, специалист службы заботы о клиентах</p>
                </Box>
                <Box className={'kurators-card'}>
                    <img src={'/images/dayana.jpeg'}  alt={'Куратор'} className={'kurators-card-img'}/>
                    <h3>Даяна</h3>

                    <p>Куратор, специалист службы заботы о клиентах</p>
                </Box>
            </Box>
        </Box>
    );
};

export default Kurators;