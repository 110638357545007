import React from 'react';
import {Box, Button} from "@mui/material";

const Authors = () => {
    return (
        <Box id={'authors'} className={'authors'} sx={{paddingTop: '100px'}}>
            <h1 className={'h1'} style={{marginBottom: '78px'}}>
                <span className={'red'}>Авторы</span> <span className={'gray'}>курса</span>
            </h1>

            <Box className={'author-kairat'}>
                <img className={'author-bg'} src={'/images/kairat/kairat-bg.png'} alt={'Background'} />
                <img className={'author-bg-mobile'} src={'/images/author-mobile-bg.png'} alt={'Background'} />
                <img className={'konus-1'} src={'/images/kairat/konus-1.png'} alt={''} />
                <img className={'konus-2'} src={'/images/kairat/konus-2.png'} alt={''} />
                <img className={'kairat-car'} src={'/images/kairat/kairat-car.png'} alt={''} />
                <img className={'kairat'} src={'/images/kairat/kairat.png'} alt={''} />
                <Box className={'kairat-text author-text-ctn'}>
                    <h3>Акшалов Кайрат</h3>
                    <p>
                        (Со-основатель первой онлайн автошколы
                        в Казахстане, маркетолог, серийный предприниматель,
                        автор курса по основам безопасности)
                    </p>
                    <Button
                        className={'author-btn'}
                        variant={'contained'}
                    >
                        <a href={'https://www.instagram.com/akshalove/'} target={'_blank'} rel="noopener noreferrer">INST @AKSHALOVE</a>
                    </Button>
                </Box>
                <Box className={'author-img-ctn'}>
                    <img className={'author-img'} src={'/images/author1.png'} alt={''}/>
                    <img className={'author-img'} src={'/images/author2.png'} alt={''}/>
                    <img className={'author-img'} src={'/images/author3.png'} alt={''}/>
                </Box>
            </Box>

            <Box className={'author-kairat'}>
                <img className={'author-bg'} src={'/images/asylzhan-bg.png'} alt={'Background'} />
                <img className={'author-bg-mobile'} src={'/images/author-mobile-bg.png'} alt={'Background'} />
                <img className={'road-sign'} src={'/images/road-sign.png'} alt={''} />
                <img className={'asylzhan'} src={'/images/asylzhan.png'} alt={''} />
                <Box className={'author-img-ctn asylzhan-img-ctn'}>
                    <img className={'author-img'} src={'/images/author1.png'} alt={''}/>
                    <img className={'author-img'} src={'/images/author2.png'} alt={''}/>
                    <img className={'author-img'} src={'/images/author3.png'} alt={''}/>
                </Box>
                <Box className={'asylzhan-text author-text-ctn'}>
                    <h3>Тлегенов Асылжан</h3>
                    <p>
                        (Со-основатель первой онлайн автошколы
                        в Казахстане, маркетолог, серийный предприниматель,
                        автор курса по основам безопасности)
                    </p>
                    <Button
                        className={'author-btn'}
                        variant={'contained'}
                    >
                        <a href={'https://www.instagram.com/founder.kz/'} target={'_blank'} rel="noopener noreferrer">INST @FOUNDER.KZ</a>
                    </Button>
                </Box>

            </Box>
        </Box>
    );
};

export default Authors;