import React, {FormEvent, useEffect, useState} from 'react';
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    MenuItem,
    Select,
    TextField
} from "@mui/material";
import Header from "../../components/Header/Header";
import Banner from "../../components/Banner/Banner";
import ForWho from "../../components/ForWho/ForWho";
import Authors from "../../components/Authors/Authors";
import Kurators from "../../components/Kurators/Kurators";
import Programm from "../../components/Programm/Programm";
import Tariffs from "../../components/Tariffs/Tariffs";
import QuestionsBanner1 from "../../components/QuestionsBanner1/QuestionsBanner1";
import Results from "../../components/Results/Results";
import Feedbacks from "../../components/Feedbacks/Feedbacks";
import FAQ from "../../components/FAQ/FAQ";
import Footer from "../../components/Footer/Footer";
import {postAppeal} from "../../api/actions";

interface FormData {
    name: string;
    phone: string;
    tariff: string;
}

const LandingPage = () => {

    const [appealOpen, setAppealOpen] = useState<boolean>(false);
    const [formData, setFormData] = useState<FormData>({
        name: '',
        phone: '',
        tariff: ''
    });
    const [requestLoading, setRequestLoading] = useState<boolean>(false);

    const [sentDialog, setSentDialog] = useState<boolean>(false);

    const handleChoose = (tariff: string) => {
        setFormData((prev) => {
            return {
                ...prev,
                tariff
            }
        })
        setAppealOpen(true);
    }

    const handleClose = () => {
        setAppealOpen(false);
    }

    const handleSelectChange = (event: any) => {
        setFormData((prev) => {
            return {
                ...prev,
                tariff: event.target.value
            }
        })
    }

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        setRequestLoading(true);

        if (!formData.name || !formData.phone) {
            alert('Необходимо заполнить все поля!')
            setRequestLoading(false);
            return;
        }

        postAppeal(formData).then((res) => {
            console.log(`Успешно отправлено`);
            setRequestLoading(false);
            setAppealOpen(false);
            setSentDialog(true);
            setFormData({
                name: '',
                phone: '',
                tariff: '15000'
            });

        })
    }

    const handleInputChange = (event:React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value = event.target.value;
        const name = event.target.name;

        setFormData((prev) => {
            return {
                ...prev,
                [name]: value
            }
        })
    }

    return (
        <Box>
            {(!sentDialog &&
                <>
                <Header/>
                <Banner />
                <ForWho />
                <Authors />
                <Kurators />
                <Programm />
                <Tariffs
                onChoose={handleChoose}
                onClose={handleClose}
                />
                <QuestionsBanner1
                    setSentDialog={setSentDialog}
                />
                <Results />
                <Feedbacks />
                <FAQ />
                <Footer
                    setSentDialog={setSentDialog}
                />
                <Dialog
                open={appealOpen}
                onClose={handleClose}
                maxWidth={'xl'}
                >
                <DialogTitle
                className={'dialog-title'}
                maxWidth={'xl'}
                sx={{background: '#FE0000', color: '#FFFFFF', fontFamily: 'Futura PT'}}
                >
                Отправка заявки
                </DialogTitle>
                <DialogContent
                className={'dialog-content'}
                >
                <form
                className={'dialog-form'}
                onSubmit={handleSubmit}
                >
                <TextField
                sx={{width: '300px'}}
                label={'Имя'}
                onChange={handleInputChange}
                name={'name'}
                />
                <TextField
                sx={{width: '300px'}}
                label={'Телефон'}
                onChange={handleInputChange}
                value={formData.phone}
                name={'phone'}
                />
                <Select
                sx={{width: '300px', borderRadius: '12px'}}
                value={formData.tariff}
                onChange={handleSelectChange}
                >
                <MenuItem value={'15000'}>Тариф - 15 000 тенге</MenuItem>
                <MenuItem value={'25000'}>Тариф - 25 000 тенге</MenuItem>
                </Select>
                {requestLoading && <CircularProgress style={{color: 'red'}}/>}
                {!requestLoading && (<Button type={'submit'} sx={{height: '56px', width: '300px'}} variant={'contained'}>
                Отправить заявку
                </Button>)}
                </form>
                </DialogContent>
                </Dialog>
                </>)}
            {sentDialog && (
                <Box className={'sent-dialog'}>
                    <h1 className={'h1 red'}>Спасибо за вашу заявку!</h1>
                    <h2 className={'h2'}>Наш менеджер свяжется с вами по указанному телефону в самое ближайшее время</h2>
                    <Button
                        onClick={() => setSentDialog(false)}
                        variant={'contained'}
                    >
                        Вернуться на сайт
                    </Button>
                </Box>
            )}
        </Box>);
};

export default LandingPage;