import React from 'react';
import {Box} from "@mui/material";

const textArray = [
    {
        h2: 'Если у вас нет прав и вы хотите их получить',
        text1: '(Курс поможет полностью с 0 освоить ПДД, Вы узнаете секреты и лайфхаки сдачи экзамена на права,',
        text2: ' получите доступ к тестам в ЦОНе, так же к подготовительному курсу по автодрому).',
        picture: '/images/1.png'
    },
    {
        h2: 'У ВАС ЕСТЬ ПРАВА, НО НЕТ ОПЫТА И ЗНАНИЙ',
        text1: '(Курс поможет получить навыки вождения по городу, после прохождения ',
        text2: 'Вы уже не будете бояться водить по городу и сможете ориентироваться в любой дорожной ситуации',
        picture: '/images/2.png'
    },
    {
        h2: 'ВЫ УЖЕ ВОДИТЕ, НО ХОТИТЕ ОСВЕЖИТЬ ЗНАНИЯ',
        text1: '(Курс поможет освежить знания ПДД, ведь каждый год вносятся изменения в ПДД, ',
        text2: 'да и формат обучения позволит сделать Вас профессионалом',
        picture: '/images/3.png'
    }
]

interface BlockProps {
    h2: string;
    text1: string;
    text2: string;
    picture: string;
}

const ForWhoBlock = ({h2, text1, text2, picture}: BlockProps) => {
    return (
        <Box className={'forwho-block'}>
            <img className={'forwho-block-img-mobile'} src={picture} alt={h2} />
            <Box className={'forwho-block-first'}>
                <h2>{h2}</h2>
                <p>
                    <b>
                        {text1}
                    </b>
                    <span>
                        {text2}
                    </span>
                </p>
            </Box>
            <img className={'forwho-block-img'} src={picture} alt={h2} />
        </Box>
    )
}

const ForWho = () => {
    return (
        <Box id={'forwho'} sx={{marginTop: '11px'}} className={'forwho'}>
            <Box className={'forwho-h1-ctn'}>
                <h1 className={'h1 red'}>Кому предназначен <span className={'gray'}>данный курс</span></h1>
            </Box>
            {textArray.map((text) => {
                return (
                    <ForWhoBlock key={text.picture} h2={text.h2} text1={text.text1} text2={text.text2} picture={text.picture} />
                )
            })}
        </Box>
    );
};

export default ForWho;