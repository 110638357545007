import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {ThemeProvider, createTheme} from "@mui/material";

const theme = createTheme({
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none', // Make button text not uppercase
                    borderRadius: '15px', // Set border radius to 15px
                    filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
                    fontFamily: 'Futura PT'
                },
                outlined: {
                    backgroundColor: '#FFFFFF', // Set background color to white for outlined variant
                    borderColor: '#FFFFFF',
                    color: '#4E4E4E',
                    '&:hover': {
                        backgroundColor: '#e7e5e5',
                        borderColor: '#e7e5e5'
                    }// Set text color to red for outlined variant
                },
                contained: {
                    backgroundColor: '#FE0000',
                    borderColor: '#FE0000',// Set background color to red for contained variant
                    color: '#FFFFFF', // Set text color to white for contained variant
                    '&:hover': {
                        backgroundColor: '#c10404', // Change the background color on hover
                        borderColor: '#c10404', // Change the border color on hover
                    },
                },
            },
        },
        MuiAccordionSummary: {
            styleOverrides: {
                root: {
                    paddingLeft: '91px',
                    fontFamily: 'Futura PT',
                    fontSize: '55px',
                    color: '#FFFFFF',
                    backgroundColor: '#FE0000',
                    height: '124px',
                    borderRadius: '21px',
                }
            }
        },
        MuiAccordionDetails: {
            styleOverrides: {
                root: {
                    fontFamily: 'Futura PT',
                    fontSize: '22px',
                    fontWeight: '100',
                    paddingLeft: '83px',
                    backgroundColor: '#FFFFFF',
                    // boxShadow: '0px 22.2938px 113.12px rgba(0, 0, 0, 0.05)',
                    // backdropFilter: 'blur(8.25697px)',
                    borderRadius: '21px'
                }
            }
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    width: '268px',
                    height: '60px',
                    color: 'black',
                    '& fieldset': {
                        background: '#FFFFFF',
                        borderRadius: '13px',
                        height: '60px'
                    },
                    '& .MuiInputLabel-root': {
                        color: '#4E4E4E',
                    },
                    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'red',
                        color: '#4E4E4E'
                    },
                    '& .Mui-focused': {
                        color: '#4E4E4E !important'
                    },
                },
            }
        }
    },
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
  </React.StrictMode>
);

