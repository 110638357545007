import React from 'react';
import {Box} from "@mui/material";

const Results = () => {
    return (
        <Box id={'results'} className={'results section'} sx={{marginTop: '100px'}}>
            <h1 className={'h1'}>
                <span className={'red'}>По результатам</span>
                <span className={'gray'}> прохождения курса вы:</span>
            </h1>
            <Box className={'results-grid'}>
                <Box className={'results-first-row'}>
                    <Box className={'results-card results-aid'}>
                        <p style={{maxWidth: '327px'}}>
                            Освоите <b>основы безопасности</b> и первой помощи
                        </p>
                    </Box>
                    <Box className={'results-card results-pdd'}>
                        <p style={{maxWidth: '303px'}}>
                            Детально будете знать <b>каждый пункт ПДД.</b>
                        </p>
                    </Box>
                </Box>
                <Box className={'results-card results-svetofor'}>
                    <p style={{maxWidth: '186px'}}>
                        Будете <b>знать все</b> о дорожных знаках
                    </p>
                </Box>
                <Box className={'results-second-row'}>
                    <Box className={'results-card results-checklist'}>
                        <p style={{maxWidth: '226px'}}>
                            Получите чек-лист <b>«Как получить права за 2 недели»</b>
                        </p>
                    </Box>
                    <Box className={'results-card results-orientation'}>
                        <p style={{maxWidth: '346px'}}>
                            Легко <b>начнете ориентироваться</b> в любых дорожных участках.
                        </p>
                    </Box>
                </Box>

                <Box className={'results-third-row'}>
                    <Box className={'results-card results-questions'}>
                        <p style={{maxWidth: '400px'}}>
                            Получите полный <b>доступ к базе тестов</b> которые будут у Вас на экзамене.
                        </p>
                    </Box>
                    <Box className={'results-card results-practice'}>
                        <p style={{maxWidth: '305px'}}>
                            Получите <b>доступ к курсу</b> по практическому экзамену ПДД.
                        </p>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default Results;