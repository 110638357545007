import React, {  useState } from 'react';
import {Box, Button, CircularProgress, TextField} from "@mui/material";
import Logo from "../Logo/Logo";
import { postAppeal } from '../../api/actions';

interface FormData {
    name: string;
    phone: string;
}

interface Props {
    setSentDialog: (formData: any) => any
}

const Footer = ({setSentDialog}: Props) => {

    const [formData, setFormData] = useState<FormData>({name: '', phone: ''});
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value = e.target.value;
        const name = e.target.name;

        setFormData((prev) => {
            return {
                ...prev,
                [name]: value,
            }
        })
    }

    const handleSubmit = () => {

        setIsLoading(true);

        if (formData.name === '' || formData.phone === '') {
            alert('Необходимо заполнить все поля');
            setIsLoading(false);
            return;
        }

        postAppeal(formData)
            .then((res) => {
                console.log(`Успешно отправлено`);
                setSentDialog(true);
                setFormData({
                    name: '',
                    phone: ''
                });
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    return (
        <Box className={'footer section'}>
            <Box className={'footer-banner'}>
                <h1 className={'h1'} style={{marginTop: '105px', color: 'white'}}>Остались вопросы?</h1>
                <p>Оставьте заявку на бесплатную консультацию</p>
                <Box className={'footer-form'}>
                    <TextField
                        sx={{marginRight: '20px'}}
                        InputLabelProps={{shrink: false}}
                        label={!formData.name ? 'Имя' : ''}
                        value={formData.name}
                        name={'name'}
                        onChange={handleChange}
                    />
                    <TextField
                        sx={{marginRight: '20px'}}
                        InputLabelProps={{shrink: false}}
                        label={!formData.phone ? 'Телефон' : ''}
                        value={formData.phone}
                        name={'phone'}
                        onChange={handleChange}
                    />
                    {isLoading && <CircularProgress sx={{color: 'white', marginTop: '5px'}}/> }
                    {!isLoading && (<Button
                        variant={'outlined'}
                        className={'footer-banner-btn'}
                        onClick={handleSubmit}
                    >
                        Задать вопрос
                        <svg style={{marginLeft: '5px'}} width="16" height="18" viewBox="0 0 16 18" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M14.0833 7.875C13.7485 7.875 13.4806 7.98047 13.2462 8.15625C13.0788 7.34766 12.4427 6.75 11.6726 6.75C11.0699 6.75 10.5677 7.10156 10.2998 7.59375C10.1324 6.78516 9.49623 6.1875 8.72614 6.1875C8.29088 6.1875 7.92257 6.36328 7.65472 6.64453V1.40625C7.65472 0.632812 7.05204 0 6.31543 0C5.54534 0 4.97614 0.632812 4.97614 1.40625V12.7617L3.10114 10.125C2.83329 9.77344 2.4315 9.5625 1.99623 9.5625C1.29311 9.5625 0.69043 10.1953 0.69043 11.0039C0.69043 11.2852 0.757394 11.5664 0.958287 11.8125L4.00516 16.1016C4.84222 17.2969 6.21498 18 7.65472 18H10.869C13.5141 18 15.6904 15.75 15.6904 12.9375V9.5625C15.6904 8.64844 14.9538 7.875 14.0833 7.875ZM8.72614 14.0625C8.72614 14.3789 8.45829 14.625 8.19043 14.625C7.88909 14.625 7.65472 14.3789 7.65472 14.0625V10.6875C7.65472 10.4062 7.88909 10.125 8.19043 10.125C8.45829 10.125 8.72614 10.4062 8.72614 10.6875V14.0625ZM10.869 14.0625C10.869 14.3789 10.6011 14.625 10.3333 14.625C10.0319 14.625 9.79757 14.3789 9.79757 14.0625V10.6875C9.79757 10.4062 10.0319 10.125 10.3333 10.125C10.6011 10.125 10.869 10.4062 10.869 10.6875V14.0625ZM13.0119 14.0625C13.0119 14.3789 12.744 14.625 12.4761 14.625C12.1748 14.625 11.9404 14.3789 11.9404 14.0625V10.6875C11.9404 10.4062 12.1748 10.125 12.4761 10.125C12.744 10.125 13.0119 10.4062 13.0119 10.6875V14.0625Z"
                                fill="black"/>
                        </svg>
                    </Button>)}
                </Box>
            </Box>
            <Box className={'footer-nav'}>
                <Logo width={'148px'} height={'36px'} className={'footer-logo'} color={'#FFFFFF'} />
                <nav className={'desktop-nav'}>
                    <a href={'#forwho'}>Для кого</a>
                    <a href={'#authors'}>Авторы курса</a>
                    <a href={'#kurators'}>Кураторы курса</a>
                    <a href={'#programm'}>Программа</a>
                    <a href={'#results'}>Результаты</a>
                    <a href={'#feedbacks'}>Отзывы</a>
                    <a href={'#faq'}>FAQ</a>
                    <a href={'https://wa.link/fa18qu'} target={'_blank'} rel="noopener noreferrer">
                        <svg style={{marginRight: 'unset'}} width={50} height={50} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="32.73 93.3 38.35 38.35">
                            <g filter="url(#filter0_bd_54_1445)">
                                <circle cx="51.9064" cy="112.479" r="19.1759" fill="white"/>
                            </g>
                            <path d="M58.5368 121.892H58.4183C44.7163 121.104 42.7708 109.542 42.4988 106.014C42.4769 105.74 42.5094 105.464 42.5943 105.202C42.6792 104.94 42.8149 104.698 42.9937 104.488C43.1725 104.279 43.3908 104.107 43.6361 103.983C43.8815 103.858 44.149 103.783 44.4234 103.762H48.2655C48.5449 103.761 48.8178 103.845 49.0491 104.002C49.2803 104.158 49.4592 104.381 49.5625 104.64L50.6224 107.248C50.7245 107.502 50.7498 107.78 50.6953 108.047C50.6407 108.315 50.5087 108.561 50.3156 108.754L48.8304 110.254C49.0624 111.572 49.6938 112.787 50.6391 113.735C51.5844 114.683 52.7981 115.318 54.1159 115.553L55.6291 114.054C55.8254 113.863 56.0735 113.734 56.3426 113.683C56.6117 113.632 56.8898 113.662 57.1422 113.768L59.7711 114.821C60.0267 114.928 60.2447 115.108 60.3974 115.339C60.5502 115.57 60.6307 115.841 60.6288 116.118V119.8C60.6288 120.355 60.4084 120.887 60.0161 121.279C59.6237 121.671 59.0917 121.892 58.5368 121.892ZM44.5907 105.156C44.4058 105.156 44.2284 105.23 44.0977 105.361C43.9669 105.491 43.8934 105.669 43.8934 105.854V105.909C44.2142 110.037 46.2713 119.8 58.495 120.497C58.5866 120.503 58.6784 120.49 58.7652 120.46C58.852 120.43 58.932 120.384 59.0007 120.323C59.0693 120.262 59.1253 120.188 59.1654 120.105C59.2055 120.023 59.2288 119.933 59.2342 119.842V116.118L56.6053 115.065L54.604 117.052L54.2693 117.011C48.2028 116.25 47.38 110.184 47.38 110.121L47.3381 109.786L49.3185 107.785L48.2725 105.156H44.5907Z" fill="#FE0000"/>
                            <defs>
                                <filter id="filter0_bd_54_1445" x="-82.6635" y="0.817878" width="269.139" height="269.139" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                                    <feGaussianBlur in="BackgroundImageFix" stdDeviation="8.48485"/>
                                    <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_54_1445"/>
                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                    <feMorphology radius="0.848485" operator="erode" in="SourceAlpha" result="effect2_dropShadow_54_1445"/>
                                    <feOffset dy="22.9091"/>
                                    <feGaussianBlur stdDeviation="58.1212"/>
                                    <feComposite in2="hardAlpha" operator="out"/>
                                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"/>
                                    <feBlend mode="normal" in2="effect1_backgroundBlur_54_1445" result="effect2_dropShadow_54_1445"/>
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_54_1445" result="shape"/>
                                </filter>
                            </defs>
                        </svg>
                    </a>
                    <a href={'https://www.instagram.com/autoonline.kz/'} target={'_blank'} rel="noopener noreferrer">
                        <img style={{width: '50px', height: '50px', objectFit: 'cover'}} src={'/images/instagram.png'} alt={'Instagram Logo'}/>
                    </a>
                </nav>

                <Logo width={'148px'} height={'36px'} className={'footer-logo-mobile'} color={'#000000'} />
                <nav className={'mobile-nav'}>
                        <svg width={50} height={50} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="32.73 93.3 38.35 38.35">
                            <g filter="url(#filter0_bd_54_1445)">
                                <circle cx="51.9064" cy="112.479" r="19.1759" fill="white"/>
                            </g>
                            <path d="M58.5368 121.892H58.4183C44.7163 121.104 42.7708 109.542 42.4988 106.014C42.4769 105.74 42.5094 105.464 42.5943 105.202C42.6792 104.94 42.8149 104.698 42.9937 104.488C43.1725 104.279 43.3908 104.107 43.6361 103.983C43.8815 103.858 44.149 103.783 44.4234 103.762H48.2655C48.5449 103.761 48.8178 103.845 49.0491 104.002C49.2803 104.158 49.4592 104.381 49.5625 104.64L50.6224 107.248C50.7245 107.502 50.7498 107.78 50.6953 108.047C50.6407 108.315 50.5087 108.561 50.3156 108.754L48.8304 110.254C49.0624 111.572 49.6938 112.787 50.6391 113.735C51.5844 114.683 52.7981 115.318 54.1159 115.553L55.6291 114.054C55.8254 113.863 56.0735 113.734 56.3426 113.683C56.6117 113.632 56.8898 113.662 57.1422 113.768L59.7711 114.821C60.0267 114.928 60.2447 115.108 60.3974 115.339C60.5502 115.57 60.6307 115.841 60.6288 116.118V119.8C60.6288 120.355 60.4084 120.887 60.0161 121.279C59.6237 121.671 59.0917 121.892 58.5368 121.892ZM44.5907 105.156C44.4058 105.156 44.2284 105.23 44.0977 105.361C43.9669 105.491 43.8934 105.669 43.8934 105.854V105.909C44.2142 110.037 46.2713 119.8 58.495 120.497C58.5866 120.503 58.6784 120.49 58.7652 120.46C58.852 120.43 58.932 120.384 59.0007 120.323C59.0693 120.262 59.1253 120.188 59.1654 120.105C59.2055 120.023 59.2288 119.933 59.2342 119.842V116.118L56.6053 115.065L54.604 117.052L54.2693 117.011C48.2028 116.25 47.38 110.184 47.38 110.121L47.3381 109.786L49.3185 107.785L48.2725 105.156H44.5907Z" fill="#FE0000"/>
                            <defs>
                                <filter id="filter0_bd_54_1445" x="-82.6635" y="0.817878" width="269.139" height="269.139" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                                    <feGaussianBlur in="BackgroundImageFix" stdDeviation="8.48485"/>
                                    <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_54_1445"/>
                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                    <feMorphology radius="0.848485" operator="erode" in="SourceAlpha" result="effect2_dropShadow_54_1445"/>
                                    <feOffset dy="22.9091"/>
                                    <feGaussianBlur stdDeviation="58.1212"/>
                                    <feComposite in2="hardAlpha" operator="out"/>
                                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"/>
                                    <feBlend mode="normal" in2="effect1_backgroundBlur_54_1445" result="effect2_dropShadow_54_1445"/>
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_54_1445" result="shape"/>
                                </filter>
                            </defs>
                        </svg>
                        <a href={'https://wa.link/fa18qu'} target={'_blank'} rel="noopener noreferrer">+7 701 113 9317</a>
                </nav>
                <nav className={'mobile-nav'}>
                    <img style={{width: '50px', height: '50px', objectFit: 'cover', marginRight: '15px'}} src={'/images/instagram.png'} alt={'Instagram Logo'}/>
                    <a href={'https://www.instagram.com/autoonline.kz/'} target={'_blank'} rel="noopener noreferrer">@autoonline.kz</a>
                </nav>
            </Box>
        </Box>
    );
};

export default Footer;