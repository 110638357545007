import React, {useEffect, useState} from 'react';
import LandingPage from "./pages/LandingPage/LandingPage";
import {Box, CircularProgress} from "@mui/material";
import Logo from "./components/Logo/Logo";

function App() {

    const [pageLoading, setPageLoading] = useState<boolean>(true);

    useEffect(() => {

        const loadEvent = () => {
            setPageLoading(false);
        }

        if (document.readyState === "complete") {
            loadEvent();
        } else {
            window.addEventListener('load', loadEvent);
        }

        return function cleanup() {
            window.removeEventListener('load', loadEvent);
        };
    })

  return (
    <div className="App">
        {pageLoading && (<Box className={'loading-screen'}>
            <Logo
                className={'header-mobile-logo'}
                width={'244'}
                height={'61'}
            />
            <CircularProgress style={{color: 'red'}}/>
        </Box>)}
        <LandingPage />
    </div>
  );
}

export default App;
