import React from 'react';
import {Box} from "@mui/material";
import Logo from "../Logo/Logo";

const Header = () => {
    return (
        <Box className={'header-ctn section'}>
            <Box className={'header'}>
                <Box className={'header-text-ctn'}>
                    <p className={'header-text-first'}>
                        Длительность
                    </p>
                    <p className={'header-text-second'}>
                        14 дней
                    </p>
                </Box>
                <Logo
                    className={'header-logo'}
                    width={'244'}
                    height={'61'}
                />
                <Box className={'header-logo-ctn'}>
                    <Logo
                        className={'header-logo-mobile'}
                        width={'244'}
                        height={'61'}
                    />
                </Box>
                <Box className={'header-text-ctn'}>
                    <p className={'header-text-first'}>
                        Формат курса
                    </p>
                    <p className={'header-text-second'}>
                        Онлайн
                    </p>
                </Box>
            </Box>
        </Box>
    );
};

export default Header;