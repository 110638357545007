import React, {useState} from 'react';
import {Accordion, AccordionDetails, AccordionSummary, Box} from "@mui/material";
import {QuestionsData} from "../../data/data";

interface FAQProps {
    title: string;
    description: string;
}

const PlusIcon = () => {
    return (
        <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_bd_53_75)">
                <circle cx="35" cy="31" r="31" fill="url(#paint0_linear_53_75)" fillOpacity="0.1" shapeRendering="crispEdges"/>
                <circle cx="35" cy="31" r="30.5" stroke="white" strokeOpacity="0.2" shapeRendering="crispEdges"/>
            </g>
            <ellipse cx="34.9998" cy="31" rx="25.7234" ry="26.1631" fill="#FE0000"/>
            <path d="M36.4927 42H33.5073V33.0428H25V29.9572H33.5073V21H36.4927V29.9572H45V33.0428H36.4927V42Z" fill="white"/>
            <defs>
                <filter id="filter0_bd_53_75" x="-32" y="-36" width="134" height="134" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feGaussianBlur in="BackgroundImageFix" stdDeviation="18"/>
                    <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_53_75"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="4"/>
                    <feGaussianBlur stdDeviation="2"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0.866667 0 0 0 0 0.866667 0 0 0 0 0.866667 0 0 0 0.2 0"/>
                    <feBlend mode="normal" in2="effect1_backgroundBlur_53_75" result="effect2_dropShadow_53_75"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_53_75" result="shape"/>
                </filter>
                <linearGradient id="paint0_linear_53_75" x1="35" y1="0" x2="35" y2="62" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#E8E8E8"/>
                    <stop offset="1" stopColor="#E8E8E8" stopOpacity="0"/>
                </linearGradient>
            </defs>
        </svg>
    )
}

const FAQModule = ({title, description}: FAQProps) => {

    const [isExpanded, setIsExpanded] = useState<boolean>(false);

    const handleExpand = (event:any, isExpanded:boolean) => {
        setIsExpanded(isExpanded);
    }

    return (
        <Accordion
            className={'faq-accordion'}
            onChange={handleExpand}
            sx={{boxShadow: '0px 2.2938px 10.12px rgba(0, 0, 0, 0.05)'}}
        >
            <AccordionSummary
                sx={{background: '#FFFFFF', color: '#4e4e4e', fontSize: '20px', lineHeight: '24px'}}
                expandIcon={!isExpanded ? <PlusIcon /> : null}
                className={'faq-accordion-summary'}
            >
                {title}
            </AccordionSummary>
            <AccordionDetails
                sx={{paddingBottom: '30px', paddingLeft: '90px'}}
                className={'faq-accordion-details'}
            >
                <p>{description}</p>
            </AccordionDetails>
        </Accordion>
    )
}
const Faq = () => {
    return (
        <Box id={'faq'} className={'FAQ section'}>
            <h1 className={'h1'}>
                <span className={'red'}>Ответы </span>
                <span className={'gray'}>на вопросы</span>
            </h1>

            {QuestionsData.map((module, index) => {
                return (
                    <FAQModule key={module.title} title={module.title} description={module.description} />
                )
            })}
        </Box>
    );
};

export default Faq;